// Architecture "x86" (alias "i386", "i686")
// Special case: Breakpad uses "efl" in place of "eflags"
export const REGISTERS_X86 = {
  eax: 0,
  ecx: 1,
  edx: 2,
  ebx: 3,
  esp: 4,
  ebp: 5,
  esi: 6,
  edi: 7,
  eip: 8,
  eflags: 9,
  efl: 9,
  unused1: 10,
  st0: 11,
  st1: 12,
  st2: 13,
  st3: 14,
  st4: 15,
  st5: 16,
  st6: 17,
  st7: 18,
  unused2: 19,
  unused3: 20,
  xmm0: 21,
  xmm1: 22,
  xmm2: 23,
  xmm3: 24,
  xmm4: 25,
  xmm5: 26,
  xmm6: 27,
  xmm7: 28,
  mm0: 29,
  mm1: 30,
  mm2: 31,
  mm3: 32,
  mm4: 33,
  mm5: 34,
  mm6: 35,
  mm7: 36,
  fcw: 37,
  fsw: 38,
  mxcsr: 39,
  es: 40,
  cs: 41,
  ss: 42,
  ds: 43,
  fs: 44,
  gs: 45,
  unused4: 46,
  unused5: 47,
  tr: 48,
  ldtr: 49,
};

// Architecture "x86_64" (alias "amd64")
export const REGISTERS_X86_64 = {
  rax: 0,
  rdx: 1,
  rcx: 2,
  rbx: 3,
  rsi: 4,
  rdi: 5,
  rbp: 6,
  rsp: 7,
  r8: 8,
  r9: 9,
  r10: 10,
  r11: 11,
  r12: 12,
  r13: 13,
  r14: 14,
  r15: 15,
  rip: 16,
  xmm0: 17,
  xmm1: 18,
  xmm2: 19,
  xmm3: 20,
  xmm4: 21,
  xmm5: 22,
  xmm6: 23,
  xmm7: 24,
  xmm8: 25,
  xmm9: 26,
  xmm10: 27,
  xmm11: 28,
  xmm12: 29,
  xmm13: 30,
  xmm14: 31,
  xmm15: 32,
  st0: 33,
  st1: 34,
  st2: 35,
  st3: 36,
  st4: 37,
  st5: 38,
  st6: 39,
  st7: 40,
  mm0: 41,
  mm1: 42,
  mm2: 43,
  mm3: 44,
  mm4: 45,
  mm5: 46,
  mm6: 47,
  mm7: 48,
  rflags: 49,
  es: 50,
  cs: 51,
  ss: 52,
  ds: 53,
  fs: 54,
  gs: 55,
  unused1: 56,
  unused2: 57,
  'fs.base': 58,
  'gs.base': 59,
  unused3: 60,
  unused4: 61,
  tr: 62,
  ldtr: 63,
  mxcsr: 64,
  fcw: 65,
  fsw: 66,
};

// Architecture "arm*" (32-bit)
// Special case: "r11" -> "fp"
export const REGISTERS_ARM = {
  r0: 0,
  r1: 1,
  r2: 2,
  r3: 3,
  r4: 4,
  r5: 5,
  r6: 6,
  r7: 7,
  r8: 8,
  r9: 9,
  r10: 10,
  r11: 11,
  fp: 11,
  r12: 12,
  sp: 13,
  lr: 14,
  pc: 15,
  f0: 96,
  f1: 97,
  f2: 98,
  f3: 99,
  f4: 100,
  f5: 101,
  f6: 102,
  f7: 103,
  fps: 24,
  cpsr: 25,
  s0: 64,
  s1: 65,
  s2: 66,
  s3: 67,
  s4: 68,
  s5: 69,
  s6: 70,
  s7: 71,
  s8: 72,
  s9: 73,
  s10: 74,
  s11: 75,
  s12: 76,
  s13: 77,
  s14: 78,
  s15: 79,
  s16: 80,
  s17: 81,
  s18: 82,
  s19: 83,
  s20: 84,
  s21: 85,
  s22: 86,
  s23: 87,
  s24: 88,
  s25: 89,
  s26: 90,
  s27: 91,
  s28: 92,
  s29: 93,
  s30: 94,
  s31: 95,
};

// Architecture "arm64" (alias aarch64)
// Special cases: "x30" -> "lr", "x29" -> "fp", "x17" -> "ip1", "x16" -> "ip0"
export const REGISTERS_ARM64 = {
  x0: 0,
  x1: 1,
  x2: 2,
  x3: 3,
  x4: 4,
  x5: 5,
  x6: 6,
  x7: 7,
  x8: 8,
  x9: 9,
  x10: 10,
  x11: 11,
  x12: 12,
  x13: 13,
  x14: 14,
  x15: 15,
  x16: 16,
  ip0: 16,
  x17: 17,
  ip1: 17,
  x18: 18,
  x19: 19,
  x20: 20,
  x21: 21,
  x22: 22,
  x23: 23,
  x24: 24,
  x25: 25,
  x26: 26,
  x27: 27,
  x28: 28,
  x29: 29,
  fp: 29,
  x30: 30,
  lr: 30,
  sp: 31,
  pc: 32,
  v0: 64,
  v1: 65,
  v2: 66,
  v3: 67,
  v4: 68,
  v5: 69,
  v6: 70,
  v7: 71,
  v8: 72,
  v9: 73,
  v10: 74,
  v11: 75,
  v12: 76,
  v13: 77,
  v14: 78,
  v15: 79,
  v16: 80,
  v17: 81,
  v18: 82,
  v19: 83,
  v20: 84,
  v21: 85,
  v22: 86,
  v23: 87,
  v24: 88,
  v25: 89,
  v26: 90,
  v27: 91,
  v28: 92,
  v29: 93,
  v30: 94,
  v31: 95,
};

// Architectures "mips" and "mips64"
export const REGISTERS_MIPS = {
  zero: 0,
  at: 1,
  v0: 2,
  v1: 3,
  a0: 4,
  a1: 5,
  a2: 6,
  a3: 7,
  t0: 8,
  t1: 9,
  t2: 10,
  t3: 11,
  t4: 12,
  t5: 13,
  t6: 14,
  t7: 15,
  s0: 16,
  s1: 17,
  s2: 18,
  s3: 19,
  s4: 20,
  s5: 21,
  s6: 22,
  s7: 23,
  t8: 24,
  t9: 25,
  k0: 26,
  k1: 27,
  gp: 28,
  sp: 29,
  fp: 30,
  ra: 31,
  lo: 32,
  hi: 33,
  pc: 34,
  f0: 35,
  f2: 36,
  f3: 37,
  f4: 38,
  f5: 39,
  f6: 40,
  f7: 41,
  f8: 42,
  f9: 43,
  f10: 44,
  f11: 45,
  f12: 46,
  f13: 47,
  f14: 48,
  f15: 49,
  f16: 50,
  f17: 51,
  f18: 52,
  f19: 53,
  f20: 54,
  f21: 55,
  f22: 56,
  f23: 57,
  f24: 58,
  f25: 59,
  f26: 60,
  f27: 61,
  f28: 62,
  f29: 63,
  f30: 64,
  f31: 65,
  fcsr: 66,
  fir: 67,
};

// Architectures "ppc" and "ppc64" (incomplete)
export const REGISTERS_PPC = {
  r0: 0,
  r1: 1,
  r2: 2,
  r3: 3,
  r4: 4,
  r5: 5,
  r6: 6,
  r7: 7,
  r8: 8,
  r9: 9,
  r10: 10,
  r11: 11,
  r12: 12,
  r13: 13,
  r14: 14,
  r15: 15,
  r16: 16,
  r17: 17,
  r18: 18,
  r19: 19,
  r20: 20,
  r21: 21,
  r22: 22,
  r23: 23,
  r24: 24,
  r25: 25,
  r26: 26,
  r27: 27,
  r28: 28,
  r29: 29,
  r30: 30,
  r31: 31,
  f0: 32,
  f1: 33,
  f2: 34,
  f3: 35,
  f4: 36,
  f5: 37,
  f6: 38,
  f7: 39,
  f8: 40,
  f9: 41,
  f10: 42,
  f11: 43,
  f12: 44,
  f13: 45,
  f14: 46,
  f15: 47,
  f16: 48,
  f17: 49,
  f18: 50,
  f19: 51,
  f20: 52,
  f21: 53,
  f22: 54,
  f23: 55,
  f24: 56,
  f25: 57,
  f26: 58,
  f27: 59,
  f28: 60,
  f29: 61,
  f30: 62,
  f31: 63,
  cr: 64,
  fpsrc: 65,
  msr: 66,
  sr0: 70,
  sr1: 71,
  sr2: 72,
  sr3: 73,
  sr4: 74,
  sr5: 75,
  sr6: 76,
  sr7: 77,
  sr8: 78,
  sr9: 79,
  sr10: 80,
  sr11: 81,
  sr12: 82,
  sr13: 83,
  sr14: 84,
  sr15: 85,
  srr0: 86,
  srr1: 87,
};
